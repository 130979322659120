.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

h1 {
  text-align: center;
}

h2 {
  text-align: center;
}
.sidebar-title {
  text-align: center;
}

.header-title {
  text-align: center;
}

.align-center {
  text-align: center
}
.header-image-portion-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  align-items: center;
  display: flex;
  justify-content: center;
}
.header-title {
  top: 10%;
}

.header-buttons {
  top: 60%;
}

#root {
  font-family: "Roboto", sans-serif;
}

.h-40 {
  height: 40%!important;
}

.h-80 {
  height: 80%!important;
}

.icon-image {
  float: left;
  height: 100%;
}

.card-header-display {
  display : flex;
}

.card-header-title {
  align-self: flex-end;
}

.side-navbar {
  overflow-y: scroll;
  max-height: calc(100vh - 5rem);
}

.navbar-sticky-top {
  top: 70px;
}

.navbar-top {
  background-color: rgba(0, 0, 0, .7)
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

.img-link {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px;
}

.img-link:hover {
  box-shadow: 0 0 1px 1px rgba(0, 140, 186, 0.5);
}